html,
body,
#__next {
  min-height: 100%;
}

/* Hides the alt tags on firefox */
img:-moz-loading {
  visibility: hidden;
}

body {
  overscroll-behavior: none; /* stop scroll bouncing */
  position: relative;
}

.main > * {
  height: 100%;
}

.uppy-Root {
  height: 100%;
}

/* Remove firefox dotted lines. */
a {
  outline: none;
}

/* Remove search styling on Chrome (x button, we add our own). */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/* Skeleton animations */
.skeleton {
  animation: timeline 2s linear infinite;
  background: linear-gradient(to right, #eee 0%, #ddd 10%, #eee 20%);
  background-size: 600px auto;
}
@keyframes timeline {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

/* Fix for mobile full height. */
.mobile-full {
  height: 100%;
  max-height: 100%;
  min-height: 100%;
}

.mobile-partial {
  min-height: 25%;
  max-height: 100%;
}
.chakra-modal__content-container {
  height: 100% !important;
}

.chakra-checkbox__control {
  pointer-events: none;
}

.osano-cm-widget--position_left {
  left: 28px;
}
