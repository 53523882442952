/* -------------------------------- */
/* --- FileOrganizer internals. --- */
/* -------------------------------- */

/* --- _FileOrganizer.scss --- */

.ui__fileOrganizer {
  /* position: relative; */
  outline: 0;
  height: 100%;

  /* Overrides */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ui__fileOrganizer__draglayer {
  overflow: visible;
  /* @include flex-center; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* @end flex-center; */
}

/* --- _DragLayer.scss --- */

.ui__dragLayer {
  pointer-events: none;
  /* @include fixed-fill; */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* @end fixed-fill; */
}

/* --- _Draggable.scss --- */

.ui__draggable {
  display: inline-block;
}
.ui__draggable__animated--inMotion {
  pointer-events: none;
}

/* ---------------------------- */
/* --- Thumbnail Internals. --- */
/* ---------------------------- */

/* --- _Image.scss --- */

.ui__image {
  max-width: 100%;
  max-height: 100%;
}

/* --- _Thumbnail.scss --- */

.ui__thumbnail__image {
  background-color: white;
}
.ui__thumbnail__image,
.ui__thumbnail__image__skeleton,
.ui__thumbnail__image__placeholder {
  border: 1px solid #dfe2ed;
}
.ui__thumbnail__image__skeleton,
.ui__thumbnail__image__placeholder {
  max-height: 100%;
}

/* --- Mobile-Desktop view --- */

.tool-picture {
  width: 0;
  height: 0;
}

.tool-picture.is-for-mobile {
  max-width: 82px;
  height: 48px;
}

@media screen and (min-width: 30em) {
  .tool-picture {
    max-width: 82px;
    height: 48px;
  }

  .tool-picture.is-for-mobile {
    width: 0;
    height: 0;
  }
}
