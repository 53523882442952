.legal-page {
  padding-bottom: 20px;
}

.legal-page > *:not(table) {
  padding-left: 32px;
  padding-right: 32px;
}

.legal-page th {
  padding-left: 32px;
  padding-right: 32px;
}

.legal-page td {
  padding-left: 32px;
  padding-right: 32px;
}

.legal-page h1 {
  font-size: 52px;
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
}

.legal-page h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
}

.legal-page h3 {
  font-size: 24px;
  font-weight: 400;
}

.legal-page h4 {
  text-decoration: underline;
}

.legal-page ul {
  margin-left: 20px !important;
  display: block;
}

.legal-page a {
  color: #0206a8;
}

.legal-page a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 49em) {
  .legal-page > *:not(table) {
    padding: 0px;
  }
}
