.nested-list-container ol {
  counter-reset: item;
}

.nested-list-container ol > li {
  display:inline-block;
}

.nested-list-container ol.rootLevel > li::before {
  font-weight: bold;
}

.nested-list-container ol > li::before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  margin-right: 20px;
}

.nested-list-container ol > li:not(:first-child) {
  margin-top: 20px;
}

.nested-list-container ol li.leaf-node-container {
  display: flex;
  margin-top: 20px;
}

.nested-list-section-header {
  margin-left: 5px;
  font-weight: bold;
}

ol.custom-lower-cased-alphabet {
  counter-reset: list;
}

ol.custom-lower-cased-alphabet > li {
  list-style: none;
}

ol.custom-lower-cased-alphabet > li:before {
  content: "("counter(list, lower-alpha) ") ";
  counter-increment: list;
}

ol.custom-lower-cased-alphabet .upper-cased-list {
  padding-left: 80px;
  margin: 20px 0;
}

ol.custom-lower-cased-alphabet > li {
  margin-top: 10px;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.section-header {
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
}

.section-header ~ .section-header  {
  /* second and subsequent section-header classes */
  margin-top: 10px;
}